
import Vue from 'vue'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import SearchTimeIcon from 'icons/search/search-icon-time.svg?inline'
import SearchIcon from 'icons/search/search-icon.svg?inline'
import SearchCategoryIcon from 'icons/search/search-category.svg?inline'
import SchevronIcon from 'icons/schevron.svg?inline'
import CloseIcon from '~/assets/icons/close-common.svg?inline'
import RPHProductCard from 'rph/molecules/RPHProductCard/index.vue'
import RPHInputSearch from 'rph/atoms/RPHInputSearch/index.vue'
import breakpoints from '@/mixins/breakpoints'

export default Vue.extend({
  name: 'RPHSearchResult',
  components: {
    RPHInputSearch,
    RPHProductCard,
    RPHButton,
    SearchTimeIcon,
    SearchIcon,
    SchevronIcon,
    SearchCategoryIcon,
    CloseIcon
  },
  mixins: [breakpoints],
  props: {
    type: {
      type: String,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    productsArr: {
      type: Object,
      default() {
        return {}
      },
    },
    loading: {
      type: Boolean,
    },
    showResults: {
      type: Boolean,
      default: false,
    },

    searchFunction: {
      type: Object,
    },
  },
  data() {
    return {
      isHidden: true,
      searchString: '',
    }
  },

  computed: {
    isSearchError() {
      return this.$store.getters['rph-products/searchError']
    },

    cardQuantity() {
      let totalQuantity = this.productsArr.key === 'popular' ? 8 : 4
      if (this.screenWidth < 1390) {
        totalQuantity = this.productsArr.key === 'popular' ? 6 : 3
      }

      if (this.screenWidth < 1025) {
        totalQuantity = 3
      }
      return this.isHidden ? totalQuantity : this.productsArr.length
    },

    isWideTabled() {
      return this.screenWidth < 1025
    }
  },

  watch: {
    searchString(val) {
      this.$emit('update-value', val)
    },
  },

  mounted() {
    if (this.screenWidth <= 1025) {
      // @ts-ignore
      this.$refs.searchstring.$refs.search.focus()

      document.body.style.overflow = 'hidden'
    }
  },

  beforeDestroy() {
    document.body.style.overflow = 'auto'
  },

  methods: {
    clearSearch() {
      this.$emit('clear-search')
    },

    searchProducts() {
      if (this.value !== 'Популярные товары') {
        this.$emit('search-product')
      }
    },

    closeSearch() {
      this.$emit('close-mobile-search')
    },
  },
})
